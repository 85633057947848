import marked from "marked";
import PlainTextRenderer from "marked-plaintext";

const plaintextOptions = {
  sanitize: false,
};

const mdToPlainText = (markdownText) => {
  const renderer = new PlainTextRenderer();
  renderer.checkbox = (text) => text;
  marked.setOptions(plaintextOptions);
  return marked(markdownText, { renderer });
};

export default mdToPlainText;
