export const navigationStyled = ({
  toggled = false,
  isSubMenu = false,
  activeLinkStyle = {},
  subMenuSx,
}) => {
  const optionBg = "white";

  const navBarMobileStyled = {
    a: {
      fontFamily: "secondary",
      fontSize: [3, null, null, 1],
      fontWeight: "medium",
      letterSpacing: 1,
      pb: [2, null, null, "2.5rem"],
      pt: [2, null, null, 4],
      px: 3,
    },
    bg: [optionBg, null, null, "unset"],
    bottom: ["0", null, null, "unset"],
    boxShadow: [3, null, null, "none"],
    li: {
      color: "inherit",
      display: "flex",
      flexDirection: "column",
      position: "relative",
    },
    maxWidth: ["36rem", null, null, "100%"],
    overflow: ["hidden", null, null, "visible"],
    position: ["fixed", null, null, "static"],
    right: ["0", null, null, "unset"],
    top: ["0", null, null, "unset"],
    transform: [`${toggled ? "" : "translateX(100%)"} `, null, null, "none"],
    transition: "transform .55s ease-in-out",
    ul: {
      flex: 1,
      flexDirection: ["column", null, null, "unset"],
      mx: [null, null, null, -3],
      p: [4, null, null, 0],
    },
    width: ["calc(100% - 4rem)", null, null, "auto"],
  };
  const navBarStyled = {
    alignItems: "center",
    display: "flex",
    flex: "auto",
    justifyContent: "center",
    ...navBarMobileStyled,
  };
  const menuStyled = {
    a: {
      "&:after": {
        border: isSubMenu ? 0 : ".05rem solid",
        borderWidth: "0 0 .05rem 0",
        bottom: [0, null, null, null, 3],
        content: '""',
        height: 0,
        left: [4, null, null, "50%"],
        maxWidth: 0,
        opacity: 0,
        position: "absolute",
        transform: "translateX(-50%)",
        transition:
          "max-width .55s .55s ease-in-out, opacity .55s .25s ease-in-out",
        width: "100%",
      },
      "&:hover": {
        "&:after": {
          maxWidth: "3rem !important",
          opacity: "1 !important",
          transition:
            "max-width .55s .25s ease-in-out, opacity .55s .25s ease-in-out",
        },
      },
      transition: "color .55s ease-in-out, background-color .55s ease-in-out",
      ...subMenuSx,
    },
    alignSelf: "center",
    display: "flex",
    justifyContent: "flex-end",
    li: {
      "&.active": {
        ul: {
          maxHeight: ["50rem", null, null, "0"],
        },
      },
      "&:hover": {
        ul: {
          maxHeight: [null, null, null, "50rem"],
          // Below property opens the dropdown with delay
          transition: "max-height .55s ease-in-out",
        },
      },
      display: "flex",
      flexDirection: "column",
      position: "relative",
      ul: {
        a: {
          fontSize: [1, null, null, 0],
          py: 3,
        },
        bg: optionBg,
        boxShadow: [null, null, null, 2],
        color: [null, null, null, "primary"],
        flexDirection: "column",
        maxHeight: "0rem",
        minWidth: [null, null, null, "22rem"],
        mx: 0,
        overflow: "hidden",
        position: [null, null, null, "absolute"],
        py: 0,
        top: [null, null, null, "100%"],
        // transition: "max-height .55s ease-in-out",
        // Below property closes the dropdown without delay
        transition: "none",
        zIndex: 2,
      },
    },
  };

  const activeLinkStyled = {
    a: {
      "&:after": {
        maxWidth: "3rem !important",
        opacity: "1 !important",
      },
      color: ["primary", null, null, "white"],
      position: "relative",
      ...activeLinkStyle,
    },
  };

  return { activeLinkStyled, menuStyled, navBarStyled };
};
