import React from "react";
import ReactMarkdown from "react-markdown";
import PropTypes from "prop-types";
import Box from "../../base/Box";

const MarkdownToHtml = ({
  heading,
  content,
  contentSx,
  contentChildren,
  source,
  headingSx,
  headingChildren,
  sx,
}) => (
  <Box
    sx={{
      ".md_content": {
        ...contentSx,
      },
      ".md_heading": {
        ...headingSx,
      },
      ...sx,
    }}
    variant="markdown"
  >
    {heading && (
      <Box className="md_heading" sx={headingSx}>
        <ReactMarkdown>{heading}</ReactMarkdown>
        {headingChildren}
      </Box>
    )}
    {(content || source) && (
      <Box className="md_content" sx={contentSx}>
        <ReactMarkdown>{content || source}</ReactMarkdown>
        {contentChildren}
      </Box>
    )}
  </Box>
);

MarkdownToHtml.propTypes = {
  content: PropTypes.string,
  contentChildren: PropTypes.node,
  contentSx: PropTypes.shape({}),
  heading: PropTypes.string,
  headingChildren: PropTypes.node,
  headingSx: PropTypes.shape({}),
  source: PropTypes.string,
  sx: PropTypes.shape({}),
};

MarkdownToHtml.defaultProps = {
  content: "",
  contentChildren: null,
  contentSx: {},
  heading: "",
  headingChildren: null,
  headingSx: {},
  source: "",
  sx: {},
};
export default MarkdownToHtml;
