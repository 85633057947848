import React from "react";
import PropTypes from "prop-types";
import { Link } from "gatsby";
import AspectRatio from "../AspectRatio";
import Image from "../Image";

const BrandLogo = ({ alt, ratio, src, sx, path }) => (
  <AspectRatio as={Link} ratio={ratio} sx={sx} to={path}>
    <Image alt={alt} svgIcon={src} />
  </AspectRatio>
);
BrandLogo.propTypes = {
  alt: PropTypes.string,
  path: PropTypes.string,
  ratio: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.number), PropTypes.number])
  ),
  src: PropTypes.string.isRequired,
  sx: PropTypes.shape({}),
};

BrandLogo.defaultProps = {
  alt: "",
  path: "",
  ratio: [1, 1],
  sx: {},
};

export default BrandLogo;
