import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const CareersOpeningContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allOpeningsJson(filter: { title: { ne: "DEFAULT" } }) {
          edges {
            node {
              details
              title
              id
            }
          }
        }
      }
    `}
    render={({ allOpeningsJson: { edges: openingsData } }) =>
      children(
        openingsData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

CareersOpeningContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default CareersOpeningContainer;
