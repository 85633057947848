import React from "react";
import PropTypes from "prop-types";
import FieldWrapper from "./FieldWrapper";
import Box from "../../base/Box";

const InputField = ({ wrapperSx, sx, ...rest }) => (
  <FieldWrapper sx={wrapperSx}>
    <Box
      as="input"
      sx={{ background: "#BCD8FF36", color: "primary", ...sx }}
      type={rest.type || "text"}
      variant="input.primary"
      {...rest}
    />
  </FieldWrapper>
);

InputField.propTypes = {
  sx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

InputField.defaultProps = {
  sx: {},
  wrapperSx: {},
};

export default InputField;
