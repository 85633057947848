import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";

const Button = ({ as, children, ...rest }) => (
  <Box as={as} variant="buttons.primary" {...rest}>
    {children}
  </Box>
);

Button.propTypes = {
  as: PropTypes.string,
  children: PropTypes.node,
};

Button.defaultProps = {
  as: "button",
  children: PropTypes.node,
};

export default Button;
