import { useLocation } from "@reach/router";
import { useState, useEffect } from "react";

const useScrollPercentage = (percentage) => {
  const [hasScrolled, setHasScrolled] = useState(false);
  const location = useLocation();

  useEffect(() => {
    const handleScroll = () => {
      const scrollHeight =
        document.documentElement.scrollHeight - window.innerHeight;
      const scrollTop = window.scrollY;
      const scrolledPercentage = (scrollTop / scrollHeight) * 100;

      if (scrolledPercentage >= percentage) {
        setHasScrolled(true);
      }
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [percentage]);

  useEffect(() => {
    // Reset the scroll flag whenever the path changes.
    setHasScrolled(false);
    window.scrollTo(0, 0); // Optionally reset scroll position on path change.
  }, [location.pathname]);

  return hasScrolled;
};

export default useScrollPercentage;
