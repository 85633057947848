import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Location } from "@reach/router";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import LinkTo from "../LinkTo";
import Icon from "../Icon";
import { navigationStyled } from "./style";

const NavGroup = ({
  ignore,
  linkList,
  linkSx,
  toggled,
  isSubMenu,
  activeLinkExtendedStyle,
  subMenuSx,
}) => {
  const {
    colors: { primary, primaryLight, white },
  } = useContext(ThemeContext);
  const { activeLinkStyled: activeLinkSx, menuStyled: menuSx } =
    navigationStyled({
      activeLinkStyle: activeLinkExtendedStyle,
      isSubMenu,
      subMenuSx,
      toggled,
    });

  const [activeNav, setActiveNav] = useState(null);
  const linkStyled = {
    alignItems: "center",
    cursor: "pointer",
    display: "inline-flex",
  };

  useEffect(() => {
    if (!toggled) {
      setActiveNav(null);
    }
  }, [toggled]);

  const hasLocation = (location, path) => {
    const { pathname } = location;
    return pathname.includes(path);
  };

  const setIconAndLabel = (icon, label) => (
    <>
      {icon && <Icon svg={icon} sx={{ width: "1.35rem" }} />}
      <Box as="span">{label}</Box>
    </>
  );

  // Commented code renders subnavigations on sidebar
  // const toggleActiveNav = (label) => {
  //   setActiveNav(activeNav ? null : label);
  // };

  return (
    <Location>
      {({ location }) => (
        <Box as="ul" sx={menuSx}>
          {linkList &&
            linkList.length > 0 &&
            linkList.map((page) => {
              const isActive = activeNav === page.label;
              return !ignore.includes(page.label) ? (
                <Box
                  key={`nav-page-${page.label}`}
                  as="li"
                  className={isActive ? "active" : ""}
                  sx={
                    hasLocation(location, page.path)
                      ? {
                          ...linkSx,
                          ...activeLinkSx,
                        }
                      : linkSx
                  }
                >
                  {page.action ? (
                    <Box as="a" onClick={() => page.action()} sx={linkStyled}>
                      {setIconAndLabel(page.icon, page.label)}
                    </Box>
                  ) : (
                    <LinkTo partiallyActive sx={linkStyled} to={page.path}>
                      {setIconAndLabel(page.icon, page.label)}
                    </LinkTo>
                  )}

                  {page?.submenu?.length > 0 && (
                    <NavGroup
                      // Active submenu styles for both mobile and desktop view
                      activeLinkExtendedStyle={{
                        backgroundColor: [white, null, null, primary],
                        color: [
                          `${primaryLight} !important`,
                          null,
                          null,
                          `${white} !important`,
                        ],
                        transition: [
                          "none",
                          null,
                          null,
                          "background-color .55s ease-in-out",
                        ],
                      }}
                      isSubMenu={
                        Array.isArray(page?.submenu) &&
                        page?.submenu?.length > 0
                      }
                      linkList={page.submenu}
                      // Submenu styles for both mobile and desktop view
                      subMenuSx={{
                        "&:hover": {
                          backgroundColor: [white, null, null, primary],
                          color: [
                            `${primaryLight} !important`,
                            null,
                            null,
                            `${white} !important`,
                          ],
                        },
                        color: `${primary} !important`,
                      }}
                    />
                  )}
                  {/* Commented code renders subnavigations on sidebar */}
                  {/* {page.submenu && (
                    <Image
                      onClick={() => toggleActiveNav(page.label)}
                      svgIcon="down"
                      sx={{
                        // color: isActive ? "primary" : "",
                        display: ["block", null, null, "none"],
                        position: "absolute",
                        right: 0,
                        transform: isActive ? "rotate(180deg)" : "",
                        transition: "transform .35s 0s",
                        width: "4.5rem",
                      }}
                    />
                  )} */}
                </Box>
              ) : null;
            })}
        </Box>
      )}
    </Location>
  );
};

NavGroup.propTypes = {
  activeLinkExtendedStyle: PropTypes.shape({}),
  ignore: PropTypes.arrayOf(PropTypes.string),
  isSubMenu: PropTypes.bool,
  linkList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  linkSx: PropTypes.shape({}),
  subMenuSx: PropTypes.shape({}),
  toggled: PropTypes.bool,
};

NavGroup.defaultProps = {
  activeLinkExtendedStyle: {},
  ignore: [],
  isSubMenu: false,
  linkSx: {},
  subMenuSx: {},
  toggled: false,
};

export default NavGroup;
