import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const FooterContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allFooterJson {
          edges {
            node {
              id
              quickContent
              backgroundImage
              callToAction {
                hideSection
                textContent {
                  heading
                  content
                }
                actionButtons {
                  simpleLink
                  hideButton
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              copyrightText
              quickLinks {
                title
                hideQuickLinks
              }
              footerLinks {
                groupTitle
                hideGroup
                linksType
                groupLinks {
                  linkLabel
                  linkPath
                  externalLink
                  iconImage
                }
              }
            }
          }
        }
        allPagesJson {
          edges {
            node {
              id
              pages {
                title
                url
                hidePage
              }
            }
          }
        }
      }
    `}
    render={({
      allFooterJson: { edges: footerData },
      allPagesJson: { edges: pagesData },
    }) =>
      children(
        footerData.map((e) => ({
          ...e.node,
        })),
        pagesData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

FooterContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default FooterContainer;
