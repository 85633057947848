/* eslint-disable prefer-destructuring */

import { rgba, lighten } from "polished";

const brandBlue = "#08446F";
const blueLight = "#BCD8FF";
const blueSoft = "#FCFFFF";
const blueSubtle = "#FCFCFF";
const blueDark = "#042238";
const skyBlueLight = "#BCD8FE";
const catalinaBlue = "#074470";
const gulfStreamGray = "#83ABB7";

const green = "#34D399";
const yellow = "#FBBF24";
const red = "#F87171";
const darkerRed = "#FB4D3D";
const white = "#ffffff";
const black = "#222222";

const grays = ["#F0F0F0", "#838383", "#707070"];

const blueTransparent = [lighten(0.2, brandBlue), lighten(0.5, brandBlue)];

/* base gradients */
const baseGradients = {
  grays: [
    [
      `linear-gradient(110deg, ${rgba(grays[0], 1)} 21%, ${rgba(
        white,
        0.85
      )} 50%)`,
      null,
      null,
      `linear-gradient(110deg, ${rgba(grays[0], 1)} 21%, ${rgba(
        white,
        0
      )} 50%)`,
    ],
    `linear-gradient(315deg, ${white} 0%, ${grays[0]} 100%)`,
    `linear-gradient(90deg, ${rgba(white, 0.96)} 34.25%, ${rgba(
      white,
      0.0
    )} 123.32%)`,
  ],
  primary: [
    `linear-gradient(315deg, ${blueDark} 0%, ${brandBlue} 100%)`,
    `linear-gradient(90deg, ${brandBlue} 0%, ${brandBlue} 75%)`,
  ],
};

const breakpoints = ["544px", "710px", "992px", "1200px", "1400px"];
breakpoints.xs = breakpoints[0];
breakpoints.sm = breakpoints[1];
breakpoints.md = breakpoints[2];
breakpoints.lg = breakpoints[3];
breakpoints.xl = breakpoints[4];

const colors = {
  black,
  blueDark,
  blueLight,
  blueSoft,
  blueSubtle,
  body: blueSoft,
  border: grays[1],
  borderLight: rgba(grays[0], 0.22),
  brandBlue,
  catalinaBlue,
  darkerRed,
  disabled: grays[0],
  error: red,
  grays,
  gulfStreamGray,
  primary: brandBlue,
  primaryDark: blueDark,
  primaryLight: blueLight,
  primaryMedium: blueTransparent[1],
  primarySoft: blueSoft,
  primarySubtle: blueSubtle,
  primaryTransparent: blueTransparent,
  skyBlueLight,
  success: green,
  text: {
    heading: brandBlue,
    link: blueTransparent[0],
    primary: grays[1],
    subtle: grays[0],
  },
  warning: yellow,
  white,
  whiteTransparent: rgba(white, 0.8),
};

const fonts = {
  display: '"LIBRARY 3 AM soft", sans-serif',
  mono: "Menlo, monospace",
  primary: '"Merriweather Sans", sans-serif',
  secondary: '"Montserrat", sans-serif',
};

const fontSizes = [
  "1.4rem",
  "1.6rem",
  "1.8rem",
  "2.4rem",
  "2.8rem",
  "3.6rem",
  "4.2rem",
  "7.2rem",
  "3rem",
];

const fontWeights = {
  black: 900,
  bold: 700,
  light: 300,
  medium: 500,
  regular: 400,
  semiBold: 600,
};

// 0.03rem, 0.05rem, 0.156rem, 0.2rem
const letterSpacings = ["normal", "0.03rem", "0.05rem", "0.156rem", "0.2rem"];
const lineHeights = ["normal", "0.8", "1.2", "1.4", "1.6"];

const mediaQueries = {
  lg: `@media screen and (min-width: ${breakpoints[3]})`,
  md: `@media screen and (min-width: ${breakpoints[2]})`,
  sm: `@media screen and (min-width: ${breakpoints[1]})`,
  xl: `@media screen and (min-width: ${breakpoints[4]})`,
  xs: `@media screen and (min-width: ${breakpoints[0]})`,
};

const radii = [0, "0.25rem", "0.5rem", "1rem", "1.5rem", "9999px"];

const shadows = [
  "none",
  `0px 3px 6px ${rgba(blueDark, 0.16)}`,
  `0px 15px 20px ${rgba(blueDark, 0.16)}`,
  `0 20px 25px -5px ${rgba(blueDark, 0.16)}, 0 10px 10px -5px ${rgba(
    blueDark,
    0.06
  )}`,
  `inset 0 2px 4px 0 ${rgba(blueDark, 0.06)}`,
];

const sizes = {
  heights: { header: ["5.1rem", "8.9rem"] },
  maxWidths: {
    button: "14rem",
    lg: "992px",
    md: "710px",
    sm: "544px",
    xl: "1280px",
    xxl: "1530px",
  },
};

const space = [
  "0",
  ".5rem",
  ".9rem",
  "1.8rem",
  "3.2rem",
  "5rem",
  "8.2rem",
  "10rem",
  "13rem",
];

const zIndices = [0, 1, 10, 20, 50, 100, 200, 9999];

const button = {
  "&:hover": { bg: "blueDark", boxShadow: 2 },
  bg: "primary",
  borderColor: "transparent",
  borderStyle: "solid",
  borderWidth: "0",
  color: "white",
  fontFamily: "secondary",
  fontSize: 1,
  fontWeight: "semiBold",
  letterSpacing: 2,
  minWidth: "6rem",
  px: 3,
  py: 3,
  transition:
    "background-color 0.35s, border-color 0.35s, color 0.35s, box-shadow 0.35s, border-color 0.2s, color 0.35s",
};

// const transparentButton = {
//   ...button,
//   "&:hover": { bg: "primary", borderColor: "primary", color: "white" },
//   bg: "inherit",
//   borderColor: "textSubtle",
//   color: "primary",
// };

const variants = {
  buttons: {
    // cancel: {
    //   ...transparentButton,
    //   "&:hover": { bg: "error", borderColor: "error", color: "white" },
    //   borderColor: "border",
    //   color: "border",
    // },
    primary: { ...button },
    primaryLink: {
      "&:hover": { color: "primaryDark" },
      color: "primary",
      fontWeight: "bold",
      py: 2,
      textDecoration: "none",
    },
    secondary: {
      ...button,
      "&:hover": { bg: "primaryDark", boxShadow: 2, color: "white" },
      bg: "primaryLight",
      color: "primary",
    },
    secondaryLink: {
      "&:hover": { color: "white" },
      color: "primaryLight",
      fontWeight: "bold",
      py: 2,
      textDecoration: "none",
    },
    // transparent: transparentButton,
  },
  input: {
    primary: {
      background: "primarySoft",
      border: 0,
      flex: 1,
      fontSize: 1,
      p: 2,
    },
  },
  markdown: {
    a: {
      "&:not(.linkToButton)": {
        "&:hover": {
          color: "primaryLight",
        },
        textDecoration: "underline",
      },
      transition:
        "background-color 0.35s, border-color 0.35s, color 0.35s, box-shadow 0.35s, border-color 0.2s, color 0.35s",
    },
    blockquote: {
      background: `linear-gradient(45deg, ${colors.primaryLight}, transparent)`,
      borderLeft: "1rem solid",
      borderRadius: 1,
      color: "primaryDark",
      ml: 0,
      mr: 0,
      p: 3,
    },
    h1: {
      color: "black",
      fontFamily: "secondary",
      fontSize: [6, null, null, 7],
      fontWeight: "bold",
      lineHeight: 2,
      mb: 3,
    },
    h2: {
      color: "black",
      fontFamily: "secondary",
      fontSize: 5,
      fontWeight: "bold",
      letterSpacing: 1,
      lineHeight: 2,
      mb: 2,
    },
    h3: {
      color: "primaryMedium",
      fontFamily: "secondary",
      fontSize: 2,
      fontWeight: "bold",
      letterSpacing: 4,
      lineHeight: 3,
      mb: 2,
      textTransform: "uppercase",
    },
    p: {
      mb: 2,
    },
    "pre, code": {
      bg: "primaryDark",
      borderRadius: 1,
      color: "white",
      display: "block",
      fontFamily: "mono",
      maxWidth: "100%",
      minWidth: "100px",
      my: 3,
      overflowX: "auto",
      p: 4,
      whiteSpace: "pre",
    },
    ul: {
      listStyle: "disc",
    },
    "ul, ol": {
      my: 3,
      pl: 4,
    },
  },
};

export default {
  // baseFontSizesForBreakpoint: ["5vw", "4vw", "2.5vw", "1.5vw", "18px"],
  baseGradients,
  breakpoints,
  colors,
  fonts,
  fontSizes,
  fontWeights,
  letterSpacings,
  lineHeights,
  mediaQueries,
  radii,
  shadows,
  sizes,
  space,
  variants,
  zIndices,
};
