import React from "react";
import { Link } from "gatsby";
import PropTypes from "prop-types";
import { Location } from "@reach/router";
import Box from "../../base/Box";

const LinkTo = ({
  activeSx,
  callTo,
  children,
  hoverSx,
  isLink,
  isButton,
  mailTo,
  sx,
  target,
  to,
  partiallyActive,
  ...rest
}) => {
  const hasLocation = (path) => (
    <Location>
      {({ location }) => {
        const { pathname } = location;
        return pathname.includes(path);
      }}
    </Location>
  );
  const hoverStyled = isButton
    ? {}
    : {
        "&:hover": {
          ...hoverSx,
        },
      };
  const linkStyled = {
    display: "inline-block",
    position: "relative",
    ...sx,
    ...hoverStyled,
  };
  const isCustomLink = isLink || callTo || mailTo;
  const setLink = (linkType) => {
    const { callTo, isLink, mailTo } = linkType;
    if (callTo) {
      return `tel:${callTo}`;
    }
    if (mailTo) {
      return `mailto:${mailTo}`;
    }
    return isLink;
  };
  return isCustomLink ? (
    <Box
      as="a"
      href={setLink({
        callTo,
        isLink,
        mailTo,
      })}
      rel="noopener norefferer"
      sx={hasLocation(to) ? { ...linkStyled, ...activeSx } : linkStyled}
      target={target}
      {...rest}
    >
      {children || isCustomLink}
    </Box>
  ) : (
    <Box
      as={Link}
      partiallyActive={partiallyActive}
      sx={hasLocation(to) ? { ...linkStyled, ...activeSx } : linkStyled}
      to={to}
      {...rest}
    >
      {children}
    </Box>
  );
};

LinkTo.propTypes = {
  activeSx: PropTypes.shape({}),
  callTo: PropTypes.string,
  children: PropTypes.node,
  hoverSx: PropTypes.shape({}),
  isButton: PropTypes.bool,
  isLink: PropTypes.string,
  mailTo: PropTypes.string,
  partiallyActive: PropTypes.bool,
  sx: PropTypes.shape({}),
  target: PropTypes.string,
  to: PropTypes.string,
};

LinkTo.defaultProps = {
  activeSx: {},
  callTo: "",
  children: null,
  hoverSx: {},
  isButton: false,
  isLink: "",
  mailTo: "",
  partiallyActive: false,
  sx: {},
  target: "",
  to: "/",
};

export default LinkTo;
