import React, { useContext } from "react";
import PropTypes from "prop-types";
import { Element } from "react-scroll";
import { ThemeContext } from "styled-components";
import Box from "../../base/Box";
import Container from "../Container";

const Section = ({
  appendContent,
  bgImage,
  bgImageSx,
  children,
  hashName,
  hashEmlSx,
  containerSx,
  overlay,
  overlaySx,
  prependContent,
  sx,
  ...rest
}) => {
  const theme = useContext(ThemeContext);
  const bgImageStyled = bgImage
    ? {
        backgroundImage: `url(${bgImage})`,
        backgroundPosition: "center",
        backgroundSize: "cover",
        ...bgImageSx,
      }
    : {};
  const sectionStyled = {
    display: "flex",
    flexDirection: "column",
    // mb: [5, null, null, null, 6],
    // mt: [5, null, null, null, 6],
    pb: [5, null, null, null, 6],
    position: "relative",
    pt: [5, null, null, null, 6],
    width: "100%",
    zIndex: 0,
    ...bgImageStyled,
    ...sx,
  };

  const HashElement = (hashName) => (
    <Box
      as={Element}
      name={hashName}
      sx={{
        bottom: `${theme.sizes.heights.header[1]}`,
        left: 0,
        pointerEvents: "none",
        position: "absolute",
        right: 0,
        top: `-${theme.sizes.heights.header[1]}`,
        visibility: "hidden",
        zIndex: "-1",
        ...hashEmlSx,
      }}
    />
  );

  return (
    <Box as="section" sx={sectionStyled} {...rest}>
      {appendContent}
      {hashName && HashElement(hashName)}
      {overlay ? (
        <Box
          sx={{
            bg: "primary",
            bottom: 0,
            left: 0,
            opacity: 0.9,
            position: "absolute",
            right: 0,
            top: 0,
            zIndex: -1,
            ...overlaySx,
          }}
        />
      ) : null}
      <Container sx={containerSx}>{children}</Container>
      {prependContent}
    </Box>
  );
};

Section.propTypes = {
  appendContent: PropTypes.node,
  bgImage: PropTypes.string,
  bgImageSx: PropTypes.shape({}),
  children: PropTypes.node.isRequired,
  containerSx: PropTypes.shape({}),
  hashEmlSx: PropTypes.shape({}),
  hashName: PropTypes.string,
  overlay: PropTypes.bool,
  overlaySx: PropTypes.shape({}),
  prependContent: PropTypes.node,
  sx: PropTypes.shape({}),
};

Section.defaultProps = {
  appendContent: null,
  bgImage: "",
  bgImageSx: {},
  containerSx: {},
  hashEmlSx: {},
  hashName: "",
  overlay: false,
  overlaySx: {},
  prependContent: null,
  sx: {},
};

export default Section;
