import React from "react";
import Box from "../../base/Box";
import { brandSettings } from "../StyledComponents/vars";
import InputField from "../InputComponents/InputField";
import Button from "../Button";
import config from "../../config.json";
import ServicesContainer from "../../containers/ServicesContainer";
import TextAreaField from "../InputComponents/TextAreaField";

export default class ContactFormCaseStudy extends React.Component {
  state = {
    emailMessage: "",
    isLoading: false,
    personEmail: "",
    showErrorMessage: false,
    showSuccessMessage: false,
  };

  handleInputChange = (event) => {
    const { target } = event;
    const { value } = target;
    const { name } = target;

    this.setState({
      [name]: value,
    });
  };

  showMessage = (res) => {
    if (res !== "error" && res.status !== 400) {
      this.setState({
        isLoading: false,
        showErrorMessage: false,
        showSuccessMessage: true,
      });
      this.clearForm();
    } else {
      this.setState({
        isLoading: false,
        showErrorMessage: true,
        showSuccessMessage: false,
      });
    }

    setTimeout(() => {
      this.hideMessage();
    }, 10000);
  };

  clearForm = () => {
    this.setState({
      emailMessage: "",
      personEmail: "",
    });
  };

  hideMessage = () => {
    this.setState({
      showSuccessMessage: false,
    });
  };

  handleSubmit = async (event) => {
    const { personEmail, emailMessage } = this.state;
    this.setState({
      isLoading: true,
    });

    event.preventDefault();

    const mailOptions = {
      emailMessage: emailMessage ? emailMessage.replace(/\n/g, "<br/>") : " ",
      emailSubject: "A potential lead contacted us",
      name: `${this.props.title}`,
      toEmailAddress: personEmail.trim(),
    };

    try {
      await fetch(config.emailApi, {
        body: JSON.stringify(mailOptions),
        headers: { "Content-Type": "application/json" },
        method: "POST",
      })
        .then((res) => {
          this.showMessage(res);
          this.props.setShowModal(false);
          this.props.setIsFormSubmitted(true);
        })
        .catch((error) => {
          this.showMessage("error");
          console.log("error", error);
        });
    } catch (e) {
      console.log("e.message", e.message);
      return e.message;
    }
  };

  render() {
    const {
      personEmail,
      emailMessage,
      isLoading,
      showSuccessMessage,
      showErrorMessage,
    } = this.state;

    const labelStyled = {
      color: "primary",
      fontSize: 1,
    };

    const columnStyled = {
      display: "flex",
      flexDirection: "column",
      mb: 4,
      px: 3,
      width: "100%",
    };

    return (
      <ServicesContainer>
        {(servicesData) => {
          const id = "services";
          const solutionInfo = servicesData
            .map((ele) => (ele.id === id ? ele : null))
            .find((el) => el);
          const { services } = solutionInfo;
          const servicesList = services.map((service) => service.serviceName);

          return (
            <form onSubmit={this.handleSubmit}>
              <Box sx={{ display: "flex", flexWrap: "wrap", mx: -3 }}>
                <Box sx={columnStyled}>
                  <Box
                    as="label"
                    sx={{
                      ...labelStyled,
                    }}
                  >
                    Email*
                  </Box>
                  <InputField
                    name="personEmail"
                    onChange={this.handleInputChange}
                    required
                    Rounded={brandSettings.radiusXs}
                    type="email"
                    value={personEmail}
                  />
                </Box>
                <Box sx={columnStyled}>
                  <Box
                    as="label"
                    sx={{
                      ...labelStyled,
                    }}
                  >
                    Message (Optional)
                  </Box>
                  <TextAreaField
                    name="emailMessage"
                    onChange={this.handleInputChange}
                    placeholder="Your feedback would be appreciated"
                    Rounded={brandSettings.radiusXs}
                    rows="6"
                    value={emailMessage}
                  />
                </Box>
                <Box
                  sx={{
                    ...columnStyled,
                    flexDirection: "row",
                    flexWrap: ["wrap", null, null, "nowrap"],
                  }}
                >
                  {showSuccessMessage && (
                    <Box sx={{ color: "success", mb: 2 }}>
                      Thank you for contacting us. We will be in touch with you
                      very soon.
                    </Box>
                  )}
                  {showErrorMessage && (
                    <Box sx={{ color: "error", mb: 2 }}>
                      There is some technical problem, try after some time.
                    </Box>
                  )}
                  <Button
                    onClick={this.props.handleClose}
                    sx={{ bg: "darkerRed", ml: "auto", mr: 3, px: 4, py: 2 }}
                  >
                    Close
                  </Button>
                  <Button sx={{ px: 4, py: 2 }} type="submit">
                    {isLoading ? "Submitting..." : "Submit"}
                  </Button>
                </Box>
              </Box>
            </form>
          );
        }}
      </ServicesContainer>
    );
  }
}
