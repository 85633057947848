import React from "react";
import PropTypes from "prop-types";
import { useLocation } from "@reach/router";
import get from "lodash/get";
import { paramCase } from "change-case";
import removeTrailingSlashes from "../../utilities/removeTrailingSlashes";
import SEOComponent from "../SEOComponent";
import seoTitleDescriptionData from "../../../admin-data/seo-title-description/seoContent.json";
import Layout from "../Layout";
import CareersOpeningContainer from "../../containers/CareersOpeningContainer";
import mdToPlainText from "../../utilities/mdToPlainText";

const LayoutMain = ({ children, ...rest }) => (
  <CareersOpeningContainer>
    {(openingsData) => {
      const path = useLocation();
      const seoData = get(seoTitleDescriptionData, "allPages");
      const { pathname } = path;
      const pagePath =
        pathname === "/" ? pathname : removeTrailingSlashes(pathname);
      const openingsDataArray = openingsData.map((opening) => ({
        ...opening,
        details: mdToPlainText(opening.details)
          .replace(/\n/g, "")
          .replace(/\t/g, " "),
        id: paramCase(opening.title),
      }));
      let title = "";
      let description = "";
      if (pagePath.includes("careers")) {
        if (pagePath === "/careers") {
          const pageSeoData = get(seoData, pagePath, {});
          title = get(pageSeoData, "title", null);
          description = get(pageSeoData, "description", null);
        } else {
          const pathId = pagePath.substring(pagePath.lastIndexOf("/") + 1);
          const openingSeoData = openingsDataArray.find(
            (opening) => opening.id === pathId
          );
          title = `Everestek | ${get(openingSeoData, "title")}`;
          description = get(openingSeoData, "details");
        }
      } else {
        const pageSeoData = get(seoData, pagePath, {});
        title = get(pageSeoData, "title", null);
        description = get(pageSeoData, "description", null);
      }
      return (
        <>
          {(title || description) && (
            <SEOComponent description={description} title={title} />
          )}
          <Layout {...rest}>{children}</Layout>
        </>
      );
    }}
  </CareersOpeningContainer>
);

LayoutMain.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default LayoutMain;
