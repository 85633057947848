/* eslint-disable */
// NOTE: this file is generated automatically, any changes will be overwritten.

import Box from "../../base/Box";
import PropTypes from "prop-types";
import React from "react";
import aboutus from "../../../static/assets/aboutus.inline.svg";
import appdevelopment from "../../../static/assets/app_development.svg";
import arrowleft from "../../../static/assets/arrow-left.inline.svg";
import arrowright from "../../../static/assets/arrow-right.inline.svg";
import arrowup from "../../../static/assets/arrow-up.inline.svg";
import automation from "../../../static/assets/automation.inline.svg";
import botcloudcomputing from "../../../static/assets/bot-cloud-computing.svg";
import botwebdesigning from "../../../static/assets/bot-web-designing.svg";
import botwebdevelopment from "../../../static/assets/bot-web-development.svg";
import brandlogo from "../../../static/assets/brand-logo.inline.svg";
import build from "../../../static/assets/build.inline.svg";
import career from "../../../static/assets/career.inline.svg";
import casestudycontact from "../../../static/assets/case-study-contact.inline.svg";
import checkicon from "../../../static/assets/check-icon.inline.svg";
import close from "../../../static/assets/close.inline.svg";
import cloudservices from "../../../static/assets/cloud_services.svg";
import contacticon from "../../../static/assets/contact-icon.inline.svg";
import contactus from "../../../static/assets/contactus.inline.svg";
import csdetailsfilterpledgemenu from "../../../static/assets/csdetails_filterpledge_menu.svg";
import csdetailsnexoolapp from "../../../static/assets/csdetails_nexool_app.svg";
import csdetailsnexoolweb from "../../../static/assets/csdetails_nexool_web.svg";
import csdetailsprojectbgmenu from "../../../static/assets/csdetails_project_bg_menu.svg";
import csdetailsprojectmenu from "../../../static/assets/csdetails_project_menu.svg";
import datascienceservices from "../../../static/assets/data_science_services.svg";
import down from "../../../static/assets/down.inline.svg";
import edtechcontentdevelopmentgamification from "../../../static/assets/edtech_content_development_gamification.svg";
import edtechdigitalpublishing from "../../../static/assets/edtech_digital_publishing.svg";
import edtechlearningplatformdevelopment from "../../../static/assets/edtech_learning_platform_development.svg";
import edtechproductdevelopment from "../../../static/assets/edtech_product_development.svg";
import email from "../../../static/assets/email.inline.svg";
import facebook from "../../../static/assets/facebook.inline.svg";
import frontend from "../../../static/assets/frontend.inline.svg";
import globalstaff from "../../../static/assets/globalstaff.inline.svg";
import herobannerartwork from "../../../static/assets/herobannerartwork.inline.svg";
import homeapp from "../../../static/assets/homeapp.inline.svg";
import homeauto from "../../../static/assets/homeauto.inline.svg";
import homecloud from "../../../static/assets/homecloud.inline.svg";
import homemobile from "../../../static/assets/homemobile.inline.svg";
import homepageherobg from "../../../static/assets/homepageherobg.svg";
import instgram from "../../../static/assets/instgram.inline.svg";
import linkedIn from "../../../static/assets/linkedIn.inline.svg";
import locationbase from "../../../static/assets/location-base.inline.svg";
import location from "../../../static/assets/location.inline.svg";
import map from "../../../static/assets/map.inline.svg";
import micro from "../../../static/assets/micro.inline.svg";
import mission from "../../../static/assets/mission.inline.svg";
import mobileservices from "../../../static/assets/mobileservices.inline.svg";
import operate from "../../../static/assets/operate.inline.svg";
import phone from "../../../static/assets/phone.inline.svg";
import quotesquare from "../../../static/assets/quote-square.inline.svg";
import quote from "../../../static/assets/quote.inline.svg";
import quotes from "../../../static/assets/quotes.inline.svg";
import services from "../../../static/assets/services.inline.svg";
import squarefacebook from "../../../static/assets/square-facebook.inline.svg";
import squarelinkedin from "../../../static/assets/square-linkedin.inline.svg";
import squaretwitter from "../../../static/assets/square-twitter.inline.svg";
import startup from "../../../static/assets/startup.inline.svg";
import theeverestekdeliverymodel from "../../../static/assets/the-everestek-delivery-model.svg";
import transfer from "../../../static/assets/transfer.inline.svg";
import twitter from "../../../static/assets/twitter.inline.svg";
import userinterface from "../../../static/assets/user_interface.svg";
import webcoding from "../../../static/assets/web_coding.svg";
import webcodingupdated from "../../../static/assets/web_coding_updated.svg";
import webuptime from "../../../static/assets/web_uptime.svg";
import workfilterpledge from "../../../static/assets/work_filterpledge.inline.svg";
import workkidup from "../../../static/assets/work_kidup.inline.svg";
import worknexool from "../../../static/assets/work_nexool.inline.svg";
import workproject from "../../../static/assets/work_project.inline.svg";
import workprovedpros from "../../../static/assets/work_provedpros.inline.svg";

const svgMap = {
  aboutus: aboutus,
  app_development: appdevelopment,
  "arrow-left": arrowleft,
  "arrow-right": arrowright,
  "arrow-up": arrowup,
  automation: automation,
  "bot-cloud-computing": botcloudcomputing,
  "bot-web-designing": botwebdesigning,
  "bot-web-development": botwebdevelopment,
  "brand-logo": brandlogo,
  build: build,
  career: career,
  "case-study-contact": casestudycontact,
  "check-icon": checkicon,
  close: close,
  cloud_services: cloudservices,
  "contact-icon": contacticon,
  contactus: contactus,
  csdetails_filterpledge_menu: csdetailsfilterpledgemenu,
  csdetails_nexool_app: csdetailsnexoolapp,
  csdetails_nexool_web: csdetailsnexoolweb,
  csdetails_project_bg_menu: csdetailsprojectbgmenu,
  csdetails_project_menu: csdetailsprojectmenu,
  data_science_services: datascienceservices,
  down: down,
  edtech_content_development_gamification: edtechcontentdevelopmentgamification,
  edtech_digital_publishing: edtechdigitalpublishing,
  edtech_learning_platform_development: edtechlearningplatformdevelopment,
  edtech_product_development: edtechproductdevelopment,
  email: email,
  facebook: facebook,
  frontend: frontend,
  globalstaff: globalstaff,
  herobannerartwork: herobannerartwork,
  homeapp: homeapp,
  homeauto: homeauto,
  homecloud: homecloud,
  homemobile: homemobile,
  homepageherobg: homepageherobg,
  instgram: instgram,
  linkedIn: linkedIn,
  "location-base": locationbase,
  location: location,
  map: map,
  micro: micro,
  mission: mission,
  mobileservices: mobileservices,
  operate: operate,
  phone: phone,
  "quote-square": quotesquare,
  quote: quote,
  quotes: quotes,
  services: services,
  "square-facebook": squarefacebook,
  "square-linkedin": squarelinkedin,
  "square-twitter": squaretwitter,
  startup: startup,
  "the-everestek-delivery-model": theeverestekdeliverymodel,
  transfer: transfer,
  twitter: twitter,
  user_interface: userinterface,
  web_coding: webcoding,
  web_coding_updated: webcodingupdated,
  web_uptime: webuptime,
  work_filterpledge: workfilterpledge,
  work_kidup: workkidup,
  work_nexool: worknexool,
  work_project: workproject,
  work_provedpros: workprovedpros,
};

const Icon = ({ svg, ...rest }) => {
  const SvgComponent = svgMap[svg];

  return (
    <Box {...rest}>
      <SvgComponent />
    </Box>
  );
};

Icon.propTypes = {
  svg: PropTypes.oneOf([
    "aboutus",
    "app_development",
    "arrow-left",
    "arrow-right",
    "arrow-up",
    "automation",
    "bot-cloud-computing",
    "bot-web-designing",
    "bot-web-development",
    "brand-logo",
    "build",
    "career",
    "case-study-contact",
    "check-icon",
    "close",
    "cloud_services",
    "contact-icon",
    "contactus",
    "csdetails_filterpledge_menu",
    "csdetails_nexool_app",
    "csdetails_nexool_web",
    "csdetails_project_bg_menu",
    "csdetails_project_menu",
    "data_science_services",
    "down",
    "edtech_content_development_gamification",
    "edtech_digital_publishing",
    "edtech_learning_platform_development",
    "edtech_product_development",
    "email",
    "facebook",
    "frontend",
    "globalstaff",
    "herobannerartwork",
    "homeapp",
    "homeauto",
    "homecloud",
    "homemobile",
    "homepageherobg",
    "instgram",
    "linkedIn",
    "location-base",
    "location",
    "map",
    "micro",
    "mission",
    "mobileservices",
    "operate",
    "phone",
    "quote-square",
    "quote",
    "quotes",
    "services",
    "square-facebook",
    "square-linkedin",
    "square-twitter",
    "startup",
    "the-everestek-delivery-model",
    "transfer",
    "twitter",
    "user_interface",
    "web_coding",
    "web_coding_updated",
    "web_uptime",
    "work_filterpledge",
    "work_kidup",
    "work_nexool",
    "work_project",
    "work_provedpros",
  ]).isRequired,
};

export default Icon;
