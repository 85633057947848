// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-components-case-study-layout-index-js": () => import("./../../../src/components/CaseStudyLayout/index.js" /* webpackChunkName: "component---src-components-case-study-layout-index-js" */),
  "component---src-components-industry-layout-index-js": () => import("./../../../src/components/IndustryLayout/index.js" /* webpackChunkName: "component---src-components-industry-layout-index-js" */),
  "component---src-components-openings-layout-index-js": () => import("./../../../src/components/OpeningsLayout/index.js" /* webpackChunkName: "component---src-components-openings-layout-index-js" */),
  "component---src-components-service-layout-index-js": () => import("./../../../src/components/ServiceLayout/index.js" /* webpackChunkName: "component---src-components-service-layout-index-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-bot-js": () => import("./../../../src/pages/bot.js" /* webpackChunkName: "component---src-pages-bot-js" */),
  "component---src-pages-careers-js": () => import("./../../../src/pages/careers.js" /* webpackChunkName: "component---src-pages-careers-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-industries-js": () => import("./../../../src/pages/industries.js" /* webpackChunkName: "component---src-pages-industries-js" */),
  "component---src-pages-insights-js": () => import("./../../../src/pages/insights.js" /* webpackChunkName: "component---src-pages-insights-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../../../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-pages-services-js": () => import("./../../../src/pages/services.js" /* webpackChunkName: "component---src-pages-services-js" */),
  "component---src-pages-work-js": () => import("./../../../src/pages/work.js" /* webpackChunkName: "component---src-pages-work-js" */)
}

