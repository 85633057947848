import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const PagesContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allPagesJson {
          edges {
            node {
              id
              pages {
                title
                url
                hidePage
                subPages {
                  title
                  url
                  hidePage
                }
              }
            }
          }
        }
      }
    `}
    render={({ allPagesJson: { edges: pagesData } }) =>
      children(
        pagesData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

PagesContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default PagesContainer;
