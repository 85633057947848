import React from "react";
import PropTypes from "prop-types";
import { camelCase } from "change-case";
import Image from "../Image";
import AspectRatio from "../AspectRatio";
import Box from "../../base/Box";
import LinkTo from "../LinkTo";

const ActionButtons = ({
  buttons,
  buttonSx,
  buttonHoverSx,
  wrapperSx,
  ...rest
}) => (
  <Box sx={{ display: "flex", mt: 4, mx: -2, ...wrapperSx }}>
    {buttons
      .filter((btn) => !btn.hideButton)
      .map((action, index) => {
        let buttonProps = {};
        if (action.customEvent) {
          buttonProps = {
            isLink: "javascript:void(0)",
            onClick: action.customEvent,
          };
        } else {
          buttonProps = action.externalLink
            ? { isLink: action.buttonPath || "/", target: "_blank" }
            : { to: action.buttonPath || "/" };
        }

        return (
          <LinkTo
            key={`${camelCase(action.buttonLabel)}-${index}`}
            className="linkToButton"
            hoverSx={{ ...buttonHoverSx }}
            sx={{
              "&:after": {
                display: "none",
              },
              alignItems: "center",
              display: "inline-flex",
              mx: 2,
              ...buttonSx,
            }}
            variant={`buttons.${action.buttonType}${
              action.simpleLink ? "Link" : ""
            }`}
            {...buttonProps}
            {...rest}
          >
            {action.buttonLabel && <span>{action.buttonLabel}</span>}
            <AspectRatio sx={{ color: "inherit", ml: 2, width: "2rem" }}>
              <Image svgIcon="arrow-right" />
            </AspectRatio>
          </LinkTo>
        );
      })}
  </Box>
);
ActionButtons.propTypes = {
  buttonHoverSx: PropTypes.shape({}),
  buttons: PropTypes.arrayOf(
    PropTypes.shape({
      buttonLabel: PropTypes.string,
      buttonPath: PropTypes.string,
      buttonType: PropTypes.string,
      customEvent: PropTypes.func,
      externalLink: PropTypes.bool,
      simpleLink: PropTypes.bool,
    })
  ).isRequired,
  buttonSx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

ActionButtons.defaultProps = {
  buttonHoverSx: {},
  buttonSx: {},
  wrapperSx: {},
};

export default ActionButtons;
