import React from "react";
import PropTypes from "prop-types";
import Box from "../../base/Box";
import NavGroup from "../NevGroup";
import { navigationStyled } from "../NevGroup/style";

const NavBar = ({
  activeLinkExtendedStyle,
  append,
  ignore,
  linkSx,
  navLinks,
  prepend,
  toggled,
}) => {
  const { navBarStyled } = navigationStyled({ toggled });
  return (
    <Box
      as="nav"
      sx={{
        ...navBarStyled,
      }}
    >
      {prepend}
      {
        <NavGroup
          activeLinkExtendedStyle={activeLinkExtendedStyle}
          ignore={ignore}
          linkList={navLinks}
          linkSx={linkSx}
          toggled={toggled}
        />
      }
      {append}
    </Box>
  );
};

NavBar.propTypes = {
  activeLinkExtendedStyle: PropTypes.shape({}),
  append: PropTypes.node,
  ignore: PropTypes.arrayOf(PropTypes.string),
  linkSx: PropTypes.shape({}),
  navLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  prepend: PropTypes.node,
  toggled: PropTypes.bool,
};

NavBar.defaultProps = {
  activeLinkExtendedStyle: {},
  append: null,
  ignore: [],
  linkSx: {},
  prepend: null,
  toggled: false,
};

export default NavBar;
