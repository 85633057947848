import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Box from "../../base/Box";
import AppBase from "../AppBase";
import config from "../../config";
import theme from "../../theme";
import Footer from "../Footer";
import Header from "../Header";
import Icon from "../Icon";
import ContactFormPopUp from "../CaseStudyLayout/ContactFormPopUp";
import Modal from "../Modal";
import useScrollPercentage from "../../utilities/useScrollPercentage";
import useShake from "../../utilities/useShake";

// Styled component with conditional animation
const ShakeComponent = styled.div`
  animation: ${({ shake }) => (shake ? "shake 0.5s ease-in-out" : "none")};
  @keyframes shake {
    0% {
      transform: translateX(0);
    }
    25% {
      transform: translateX(-5px);
    }
    50% {
      transform: translateX(5px);
    }
    75% {
      transform: translateX(-5px);
    }
    100% {
      transform: translateX(0);
    }
  }
`;
const Layout = ({ children, ...rest }) => {
  const [hasDom, setHasDom] = useState(false);

  useEffect(() => {
    if (typeof window !== "undefined") setHasDom(true);
  }, []);

  const { pathname } = children?.props?.location;
  const joinedPath = pathname === "/" ? "home" : pathname.split("/").join(" ");

  const isCaseStudy = pathname.includes("casestudies");
  const pathsToHideChatIcon = ["/about/", "/careers/", "/contact/"];

  const [showModal, setShowModal] = useState(false);
  const [isFormSubmitted, setIsFormSubmitted] = useState(false);

  const hasReached30 = useScrollPercentage(30);

  useEffect(() => {
    if (isCaseStudy && hasReached30) {
      setShowModal(true); // opens when route is caseStudy
    } else {
      setShowModal(false);
    }
  }, [isCaseStudy, hasReached30]);

  const shake = useShake(3000, 500);

  // Handle modal close
  const closeModal = (event) => {
    event.preventDefault();
    setShowModal(false);
  };

  return (
    hasDom && (
      <>
        <AppBase config={config} theme={theme}>
          <Header />
          <Box as="main">
            {typeof children === "function" ? children(rest) : children}

            <>
              <Modal
                bodySx={{ p: 4 }}
                isOpen={showModal && !isFormSubmitted}
                onClose={closeModal}
                style={{
                  content: {
                    height: "auto",
                  },
                }}
                title="How can we assist you?"
              >
                <ContactFormPopUp
                  handleClose={closeModal}
                  setIsFormSubmitted={setIsFormSubmitted}
                  setShowModal={setShowModal}
                  title={joinedPath}
                />
              </Modal>
              {!pathsToHideChatIcon.includes(pathname) && !isFormSubmitted && (
                <Box
                  sx={{
                    bottom: 4,
                    position: "fixed",
                    right: 4,
                    zIndex: 50,
                  }}
                >
                  <ShakeComponent shake={shake}>
                    <Icon
                      onClick={() => {
                        setShowModal(true);
                      }}
                      svg="contact-icon"
                      sx={{
                        cursor: "pointer",
                        filter: "drop-shadow(0px 5px 10px gray)",
                        svg: {
                          width: ["6rem", "8rem"],
                        },
                      }}
                    />
                  </ShakeComponent>
                </Box>
              )}
            </>
          </Box>
          <Footer />
        </AppBase>
      </>
    )
  );
};

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.func, PropTypes.node]).isRequired,
};

export default Layout;
