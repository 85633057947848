import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React from "react";

const TwitterCard = ({
  description,
  image,
  title,
  type,
  username,
  twitterId,
}) => (
  <Helmet>
    <meta content={description} name="twitter:description" />
    <meta content={description} name="twitter:image:alt" />
    <meta content={image} name="twitter:image" />
    <meta content={title} name="twitter:title" />
    <meta content={type} name="twitter:card" />
    {username && <meta content={`@${username}`} name="twitter:creator" />}
    {username && <meta content={`@${username}`} name="twitter:site" />}
    {twitterId && <meta content={twitterId} name="twitter:site:id" />}
    {twitterId && <meta content={twitterId} name="twitter:creator:id" />}
  </Helmet>
);

TwitterCard.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  twitterId: PropTypes.string,
  type: PropTypes.oneOf(["summary_large_image"]),
  username: PropTypes.string,
};

TwitterCard.defaultProps = {
  twitterId: null,
  type: "summary_large_image",
  username: null,
};

export default TwitterCard;
