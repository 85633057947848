import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React from "react";

const LinkedInCard = ({ description, image, name, title, url }) => (
  <Helmet>
    <meta content={description} property="og:description" />
    <meta content={image} property="og:image" />
    <meta content={title} property="og:title" />
    <meta content={url} property="og:url" />
    {name && <meta content={name} property="og:site_name" />}
  </Helmet>
);

LinkedInCard.propTypes = {
  description: PropTypes.string.isRequired,
  image: PropTypes.string.isRequired,
  name: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

LinkedInCard.defaultProps = {
  name: null,
};

export default LinkedInCard;
