import React from "react";
import PropTypes from "prop-types";
import { brandSettings } from "../StyledComponents/vars";
import Box from "../../base/Box";

export const FieldStyle = (p) => `
  ${p && p.Rounded ? `border-radius: ${p.Rounded || brandSettings.radius}` : ""}
  flex: 1;
  border: .1rem solid var(--color-light1);
  padding: ${brandSettings.space_xs};
`;

const FieldWrapper = ({ className, children, sx }) => (
  <Box
    className={className}
    sx={{
      display: "flex",
      flexDirection: "row",
      position: "relative",
      ...sx,
    }}
  >
    {children}
  </Box>
);

FieldWrapper.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  sx: PropTypes.shape({}),
};

FieldWrapper.defaultProps = {
  children: null,
  className: "",
  sx: {},
};

export default FieldWrapper;
