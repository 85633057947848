const color1 = "#0077BA";
const color2 = "#E8F4FB";
const color3 = "#FFFFFF";
const color4 = "#555555";
const color5 = "#999999";
const color6 = "#353535";
const color7 = "#BABABA";
const color8 = "#CCCCCC";
const color9 = "#f2f2f2";
const color10 = "#005585";
const color11 = "#000000";
const color12 = "#7DBEE2";
const color13 = "#666666";
const color14 = "#C53030";
const color15 = "#2F855A";
const color16 = "#07446F";

const fontFamilyBase = `-apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif`;
const fontSans = "'Arial', sans-serif";

const fontFamily1 = `'Poppins', ${fontSans}, ${fontFamilyBase}`;
const fontFamily2 = `'Saira Extra Condensed', ${fontSans}, ${fontFamilyBase}`;
const fontFamily3 = `'Pattaya', ${fontSans}, ${fontFamilyBase}`;
const fontFamily4 = `'LIBRARY 3 AM soft', ${fontSans}, ${fontFamilyBase}`;

const fontSizeDefault = "1.4rem";
const fontSizeMedium = "1.6rem";
const fontSizeDisplay = "8.5rem";
const fontSizeDisplay_1 = "6rem";
const baseFontWeight = "normal";
const baseLineHeight = 1.5;

const radius = ".5rem";
const radius_xs = ".3rem";
const radiusFull = "9999px";

const boxShadow = ".2rem .2rem .2rem rgba(170, 170, 170, 0.25)";
const boxShadow_sm = "0 .4rem .4rem rgba(0, 0, 0, 0.25)";
const boxShadow_md = ".9rem .9rem .6rem rgba(170, 170, 170, 0.25);";
const boxShadow_button = "3px 3px 6px #C8C8C8";
const boxShadow_inset = "inset 0 .2rem .2rem rgba(0, 0, 0, 0.25);";
const textShadow = "0rem .4rem .4rem rgba(0, 0, 0, 0.25)";

export const brandSettings = {
  //  Column Grid
  ColumnGrid: 12,

  // Break points
  breakPoints: {
    bp_xs: "0px",
    bp_sm: "576px",
    bp_md: "710px",
    bp_lg: "992px",
    bp_xl: "1280px",
  },

  // Containers
  containers: {
    size_sm: "57.6rem",
    size_md: "76.8rem",
    size_lg: "90.6rem",
    size_xl: "128rem",
  },

  // colors
  colorPrimary: color1,
  colorPrimaryLight: color12,
  colorPrimaryDark: color16,
  colorSecondary: color2,
  colorWhite: color3,
  colorBlack: color11,
  colorBase: color4,
  colorBaseLight: color13,
  colorDark: color6,
  colorLight: color5,
  colorLight1: color7,
  colorLight2: color8,
  colorLight3: color9,
  colorDanger: color14,
  colorSuccess: color15,

  // font family
  fontPrimary: fontFamily1,
  fontSecondary: fontFamily2,
  fontQuotes: fontFamily3,
  fontDisplay: fontFamily4,

  // font settings
  fontSizeDefault,
  fontSizeMedium,
  FontSizeDisplay: fontSizeDisplay,
  FontSizeDisplay_1: fontSizeDisplay_1,
  fontWeight: baseFontWeight,
  lineHeight: baseLineHeight,

  // radius
  radius,
  radiusXs: radius_xs,
  radius_full: radiusFull,
  radius_0: 0,

  // shadows
  shadow: boxShadow,
  shadow_sm: boxShadow_sm,
  shadow_md: boxShadow_md,
  shadow_btn: boxShadow_button,
  boxShadow_inset,
  textShadow,

  // space
  space_xxs: ".5rem",
  space_xs: ".9rem",
  space_sm: "1.8rem",
  space_md: "3.2rem",
  space_lg: "5rem",
  space_xl: "8rem",
  space_xxl: "10rem",

  // borders
  borderLight: "rgba(242, 242, 242, 0.22)",

  //  Transition
  transitionTiming: 0.35,
  transitionEasing: "ease-in-out",
};
