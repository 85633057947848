import React, { useContext, useEffect, useState } from "react";
import { graphql, StaticQuery } from "gatsby";
import { globalHistory, Location, useLocation } from "@reach/router";
import { ThemeContext } from "styled-components";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import Box from "../../base/Box";
import Container from "../Container";
import BrandLogo from "../BrandLogo";
import Navigation from "../Navigation";
import Hamburger from "../Hamburger";
import PagesContainer from "../../containers/PagesContainer";
import { CASESTUDIES, SUBPAGES_LIST } from "../../utilities/constants";

const Header = () => {
  const theme = useContext(ThemeContext);
  const [toggled, setToggled] = useState(false);
  const [isScrolled, setIsScrolled] = useState(false);

  useEffect(
    () =>
      globalHistory.listen(({ action }) => {
        if (action === "PUSH") setToggled(false);
      }),
    [toggled]
  );

  useScrollPosition(
    ({ currPos }) => {
      const isShow = currPos.y > 0;
      if (isShow !== isScrolled) setIsScrolled(isShow);
    },
    [isScrolled],
    null,
    true
  );

  const path = useLocation();
  const { pathname } = path;
  const isCaseStudies = pathname.includes(CASESTUDIES);

  const containerStyled = {
    alignItems: "center",
    display: "flex",
    justifyContent: "space-between",
    position: "static",
  };

  const handleToggle = () => {
    setToggled(!toggled);
  };

  const headerStyled = {
    ":before": {
      bg: "primaryDark",
      bottom: 0,
      content: '""',
      left: 0,
      opacity: isScrolled || isCaseStudies ? 1 : 0,
      position: "absolute",
      right: 0,
      top: 0,
      transition: "all .55s 0s ease",
      zIndex: -1,
    },
    "~ main": {
      mt: [
        theme.sizes.heights.header[0],
        null,
        null,
        theme.sizes.heights.header[1],
      ],
    },
    // borderBottom: "1px solid",
    // borderColor: "borderLight",
    // color: ["text.primary", null, null, isScrolled ? "white" : ""],
    left: 0,
    position: "fixed",
    right: 0,
    zIndex: 5,
  };

  const getTitleAndLabel = (menuList) =>
    menuList.map((item) => ({
      label: item.title,
      path: item.url,
      submenu: item.subPages ? getTitleAndLabel(item.subPages) : null,
    }));

  return (
    <StaticQuery
      query={graphql`
        query HeadingQuery {
          site {
            siteMetadata {
              logo
              siteName
            }
          }
        }
      `}
      render={(data) => {
        const { siteName, logo } = data.site.siteMetadata;
        return (
          <Location>
            {({ location }) => {
              const { pathname } = location;
              const regex = /[a-zA-Z]\/[a-zA-Z]/gm;

              const isSubPage = SUBPAGES_LIST.some(
                (subpage) => pathname.includes(subpage) && regex.test(pathname)
              );

              // Commented code which blurs out header on Careers page
              // const isCareerOpeningPage =
              //   pathname.includes("careers") || isSubPage;
              return (
                <PagesContainer>
                  {(pagesData) => {
                    const { pages } = pagesData.find(
                      (item) => item.id === "pages"
                    );
                    const allPages = pages.filter((i) => !i.hidePage);
                    const pageList =
                      allPages && allPages.length > 0
                        ? getTitleAndLabel(allPages)
                        : [];
                    return (
                      <Box
                        as="header"
                        sx={{
                          ...headerStyled,
                          color: [
                            "text.primary",
                            null,
                            null,
                            isScrolled || !isSubPage ? "primaryLight" : "",
                          ],
                        }}
                      >
                        <Container sx={containerStyled}>
                          <BrandLogo
                            alt={siteName}
                            path="/"
                            ratio={[94, 32]}
                            src={logo}
                            sx={{
                              color:
                                isScrolled || !isSubPage ? "white" : "primary",
                              flex: "none",
                              my: 2,
                              width: "9.4rem",
                            }}
                          />
                          <Hamburger
                            innerSx={{ m: 1 }}
                            onClick={handleToggle}
                            sx={{
                              bg: "transparent",
                              borderRadius: 1,
                              color: toggled
                                ? "primary"
                                : `${
                                    isScrolled || !isSubPage
                                      ? "white"
                                      : "primaryDark"
                                  }`,
                              display: ["inline-block", null, null, "none"],
                              flex: "none",
                              position: "fixed",
                              right: "1.5rem",
                              width: "30px",
                              zIndex: 6,
                            }}
                            toggled={toggled}
                          />
                          {pageList.length > 0 && (
                            <Navigation
                              activeLinkStyle={{
                                color: [
                                  "primary",
                                  null,
                                  null,
                                  isScrolled || !isSubPage
                                    ? "white"
                                    : "primary",
                                ],
                              }}
                              ignore={["Home"]}
                              navLinks={pageList}
                              toggled={toggled}
                            />
                          )}
                        </Container>
                      </Box>
                    );
                  }}
                </PagesContainer>
              );
            }}
          </Location>
        );
      }}
    />
  );
};

export default Header;
