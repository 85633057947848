import PropTypes from "prop-types";
import React, { useContext } from "react";
import ReactModal from "react-modal";
import { createGlobalStyle, ThemeContext } from "styled-components";
import Box from "../../base/Box";
import Button from "../Button";
import Heading from "../Heading";
import Icon from "../Icon";

const StyledGlobal = createGlobalStyle`
  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 200ms ease-in-out;
    z-index: ${(p) => p.theme.zIndices[5]};
  }
  .ReactModal__Overlay--after-open{
    opacity: 1;
  }
  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
  .ReactModal__Content {
    transition: transform 200ms ease-in-out;
    transform: translate(-50%, -52%);
  }
  .ReactModal__Content--after-open{
    transform: translate(-50%, -50%);
  }
  .ReactModal__Content--before-close{
    transform: translate(-50%, -52%);
  }
`;

const Modal = ({
  applyForm,
  bodySx,
  cancelText,
  children,
  closeText,
  footer,
  footerContent,
  onClose,
  onSubmit,
  style,
  submitText,
  submitting,
  title,
  ...rest
}) => {
  const theme = useContext(ThemeContext);

  return (
    <ReactModal
      ariaHideApp={false}
      closeTimeoutMS={200}
      onRequestClose={onClose}
      shouldCloseOnEsc
      shouldCloseOnOverlayClick
      style={{
        content: {
          border: "none",
          borderRadius: theme.radii[3],
          bottom: "auto",
          boxShadow: theme.shadows[3],
          display: "flex",
          flexDirection: "column",
          height: `calc(100% - ${theme.space[5]} * 2`,
          left: "50%",
          maxWidth: theme.sizes.maxWidths.lg,
          overflow: "visible",
          padding: theme.space[0],
          right: "auto",
          top: "50%",
          width: `calc(100% - ${theme.space[5]} * 2`,
          ...style.content,
        },
        overlay: {
          backgroundColor: "rgba(0, 0, 0, 0.8)",
          ...style.overlay,
        },
      }}
      {...rest}
    >
      <StyledGlobal />
      <Button
        disabled={submitting}
        onClick={onClose}
        sx={{
          position: "absolute",
          right: 3,
          top: 3,
          // transform: "translate(25%, -25%)",
          zIndex: theme.zIndices[1],
        }}
        variant="buttons.white.icon"
      >
        {closeText || <Icon svg="close" sx={{ width: "2.5rem" }} />}
      </Button>
      {title && (
        <Box
          as="header"
          sx={{
            borderTopLeftRadius: theme.radii[3],
            borderTopRightRadius: theme.radii[3],
            boxShadow: theme.shadows[3],
            p: theme.space[3],
            position: "relative",
            textTransform: "uppercase",
          }}
          variant="sections.primary"
        >
          <Heading as="h3">{title}</Heading>
        </Box>
      )}
      <Box
        sx={{
          flex: "auto",
          overflow: "auto",
          p: theme.space[3],
          ...bodySx,
        }}
      >
        {children}
      </Box>
      {!applyForm && (footer || footerContent) && (
        <Box
          sx={{
            borderBottomLeftRadius: theme.radii[3],
            borderBottomRightRadius: theme.radii[3],
            boxShadow: theme.shadows[3],
            display: "flex",
            justifyContent: "center",
            p: 6,
            position: "relative",
          }}
        >
          {footerContent}
          {cancelText && (
            <Button
              disabled={submitting}
              onClick={onClose}
              sx={{ mx: theme.space[2] }}
              type="submit"
              variant="buttons.secondary"
            >
              {cancelText}
            </Button>
          )}
          {submitText && (
            <Button
              onClick={onSubmit}
              submitting={submitting}
              sx={{ mx: theme.space[2] }}
              type="submit"
              variant="buttons"
            >
              {submitText}
            </Button>
          )}
        </Box>
      )}
    </ReactModal>
  );
};

Modal.propTypes = {
  applyForm: PropTypes.bool,
  bodySx: PropTypes.shape({}),
  cancelText: PropTypes.string,
  children: PropTypes.node.isRequired,
  closeText: PropTypes.string || PropTypes.node,
  footer: PropTypes.bool,
  footerContent: PropTypes.node,
  onClose: PropTypes.func.isRequired,
  onSubmit: PropTypes.func,
  style: PropTypes.shape({
    content: PropTypes.shape({}),
    overlay: PropTypes.shape({}),
  }),
  submitText: PropTypes.string,
  submitting: PropTypes.bool,
  title: PropTypes.string,
};

Modal.defaultProps = {
  applyForm: null,
  bodySx: {},
  cancelText: "Cancel",
  closeText: null,
  footer: null,
  footerContent: null,
  onSubmit: null,
  style: {},
  submitText: "Submit",
  submitting: false,
  title: null,
};

export default Modal;
