import { createGlobalStyle } from "styled-components";

const StyledGlobal = createGlobalStyle`
  * {
    box-sizing: border-box;
  }

  html {
    height: 100%;
    overflow-x: hidden;
    background-color: ${(p) => p.theme.colors.primarySubtle};
    font-size: 62.5%;
  }

  body {
    color: ${(p) => p.theme.colors.text.primary};
    font-family: ${(p) => p.theme.fonts.primary};
    font-size: ${(p) => p.theme.fontSizes[1]};
    font-weight: ${(p) => p.theme.fontWeights.light};
    letter-spacing: ${(p) => p.theme.letterSpacings[2]};
    line-height: ${(p) => p.theme.lineHeights[4]};
    position: relative;
    width: 100%;
    margin: 0;
    max-width: 100%;
    min-height: 100vh;
    overflow-x: hidden;;
    padding: 0;
    display: flex;
    flex-direction: column;
    > div {
      &, > div, main {
        display: flex;
        flex-direction: column;
        flex: auto;
      }
    }
  }

  a {
    color: inherit;
    text-decoration: none;
  }

  button,
  input[type='button'],
  input[type='submit'] {
    padding: 0;
    border: none;
    background: none;
    color: inherit;
    font: inherit;
    cursor: pointer;
    outline: none;
  }

  textarea {
    display: block;
    resize: vertical;
  }

  a,
  input,
  button,
  textarea {
    font: inherit;
    outline: none;

    &:focus,
    &:active {
      outline: none;
    }

    &[type='radio'],
    &[type='checkbox'] {
      margin: 0;
    }
  }

  figure,
  h1,
  h2,
  h3,
  h4,
  h5,
  h6,
  li,
  p,
  pre,
  ul {
    margin: 0;
    padding: 0;
  }

  h1,
  h2,
  h3,
  h4 {
    font-family: ${(p) => p.theme.fonts.secondary};
    font-weight: normal;
  }

  h1 {
    font-size: ${(p) => p.theme.fontSizes[7]};
  }

  h2 {
    font-size: ${(p) => p.theme.fontSizes[5]};
  }

  h3 {
    font-size: ${(p) => p.theme.fontSizes[2]};
  }

  h4 {
    font-weight: ${(p) => p.theme.fontWeights.bold};
  }

  ul {
    list-style: none;
  }

  hr {
    height: 1px;
    border: 0;
    background-color: ${(p) => p.theme.colors.border};
  }
  
  // * {
  //   outline: 1px solid cyan;
  // }
`;

export default StyledGlobal;
