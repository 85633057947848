import React from "react";
import PropTypes from "prop-types";
import NavBar from "../Navbar";

const Navigation = ({ navLinks, toggled, activeLinkStyle, ...rest }) => (
  <NavBar
    activeLinkExtendedStyle={activeLinkStyle}
    navLinks={navLinks}
    toggled={toggled}
    {...rest}
  />
);

Navigation.propTypes = {
  activeLinkStyle: PropTypes.shape({}),
  navLinks: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  toggled: PropTypes.bool,
};

Navigation.defaultProps = {
  activeLinkStyle: {},
  toggled: false,
};

export default Navigation;
