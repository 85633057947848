import PropTypes from "prop-types";
import React from "react";
import { StaticQuery, graphql } from "gatsby";

const ServicesContainer = ({ children }) => (
  <StaticQuery
    query={graphql`
      query {
        allServicesJson {
          edges {
            node {
              id
              pageId
              pageTitle
              pageBanner {
                hideSection
                textContent {
                  heading
                  content
                }
                backgroundImage
              }
              servicesOrder {
                serviceId
              }
              introduction {
                hideSection
                textContent {
                  heading
                  content
                }
                images {
                  image
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              services {
                hideService
                serviceName
                textContent {
                  heading
                  content
                }
                image
                keyCompetency
                briefing
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
                relativeImages {
                  image
                }
              }
            }
          }
        }
        allServicesListJson(
          filter: { title: { ne: "DEFAULT" } }
          sort: { fields: title, order: ASC }
        ) {
          edges {
            node {
              id
              title
              serviceId
              hideService
              keyCompetency
              briefing
              relativeImages {
                image
              }
              summary {
                heading
                content
                backgroundImage
                hideDetailButton
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              introduction {
                hideSection
                heading
                content
                images {
                  image
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
              featuredBlogs
              technology {
                heading
                hideSection
                technologyList {
                  image
                  name
                  link
                }
              }
              ctaSection {
                hideSection
                textContent {
                  heading
                  content
                }
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
              }
            }
          }
        }
        allBlogsJson(filter: { title: { ne: "DEFAULT" } }) {
          edges {
            node {
              blogId
              title
              hideBlog
              summary {
                image
                actionButtons {
                  hideButton
                  simpleLink
                  externalLink
                  buttonType
                  buttonLabel
                  buttonPath
                }
                heading
                content
              }
            }
          }
        }
        allBenefitsJson(
          filter: { title: { ne: "Default [DO NOT REMOVE OR EDIT]" } }
        ) {
          edges {
            node {
              id
              hideSection
              title
              benefits {
                hideBenefit
                content
              }
            }
          }
        }
      }
    `}
    render={({
      allServicesJson: { edges: servicesData },
      allServicesListJson: { edges: serviceData },
      allBenefitsJson: { edges: benefitsData },
      allBlogsJson: { edges: blogsData },
    }) =>
      children(
        servicesData.map((e) => ({
          ...e.node,
        })),
        serviceData.map((e) => ({
          ...e.node,
        })),
        benefitsData.map((e) => ({
          ...e.node,
        })),
        blogsData.map((e) => ({
          ...e.node,
        }))
      )
    }
  />
);

ServicesContainer.propTypes = {
  children: PropTypes.func.isRequired,
};

export default ServicesContainer;
