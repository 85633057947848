import { useState, useEffect } from "react";

const useShake = (intervalTime = 5000, shakeDuration = 500) => {
  const [shouldShake, setShouldShake] = useState(false);

  useEffect(() => {
    const interval = setInterval(() => {
      setShouldShake(true); // Trigger shake
      setTimeout(() => setShouldShake(false), shakeDuration); // Stop shake after duration
    }, intervalTime);

    return () => clearInterval(interval); // Cleanup interval on unmount
  }, [intervalTime, shakeDuration]);

  return shouldShake;
};

export default useShake;
