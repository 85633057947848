import React from "react";
import PropTypes from "prop-types";
import FieldWrapper from "./FieldWrapper";
import Box from "../../base/Box";

const TextAreaField = ({ wrapperSx, sx, ...rest }) => (
  <FieldWrapper sx={wrapperSx}>
    <Box
      as="textarea"
      sx={{ background: "#BCD8FF36", color: "primary", resize: "none", ...sx }}
      variant="input.primary"
      {...rest}
    />
  </FieldWrapper>
);

TextAreaField.propTypes = {
  sx: PropTypes.shape({}),
  wrapperSx: PropTypes.shape({}),
};

TextAreaField.defaultProps = {
  sx: {},
  wrapperSx: {},
};

export default TextAreaField;
