import { Helmet } from "react-helmet";
import PropTypes from "prop-types";
import React from "react";
import { useLocation } from "@reach/router";
import config from "../../config.json";

const GenericTags = ({
  author,
  description,
  image,
  keywords,
  language,
  title,
  url,
  googleSiteVerificationCode,
  primaryColor,
  stage,
  enableGoogleSearchEnvironment,
}) => {
  const location = useLocation();
  const isServicePage = (config?.pathsForGoogleAnalyticsTag || []).some(
    (path) => location.pathname.includes(path)
  );
  return (
    <Helmet>
      <html
        lang={language}
        prefix="og: https://ogp.me/ns#"
        xmlns="w3.org/1999/xhtml"
      />
      <title>{title}</title>
      <link href={url} rel="canonical" />
      <meta content="width=device-width, initial-scale=1" name="viewport" />
      <meta content={image} property="og:image" />
      <meta content={keywords} name="keywords" />
      <meta content={description} name="description" />
      <meta content={author} name="author" />
      <meta content={primaryColor} name="msapplication-navbutton-color" />
      <meta content={primaryColor} name="theme-color" />
      <meta
        content={primaryColor}
        name="apple-mobile-web-app-status-bar-style"
      />
      {config.googleAnalyticsTrackingIdv2 && isServicePage && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${config.googleAnalyticsTrackingIdv2}}`}
        />
      )}
      {config.googleAnalyticsTrackingIdv2 && isServicePage && (
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${config.googleAnalyticsTrackingIdv2}");
        `}
        </script>
      )}
      {config.GA4v2 && isServicePage && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${config.GA4v2}}`}
        />
      )}
      {config.GA4v2 && isServicePage && (
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${config.GA4v2}");
        `}
        </script>
      )}
      {config.GA4 && (
        <script
          async
          src={`https://www.googletagmanager.com/gtag/js?id=${config.GA4}}`}
        />
      )}
      {config.GA4 && (
        <script>
          {`
          window.dataLayer = window.dataLayer || [];
          function gtag(){dataLayer.push(arguments);}
          gtag('js', new Date());
          gtag('config', "${config.GA4}");
        `}
        </script>
      )}
      {googleSiteVerificationCode && (
        <meta
          content={googleSiteVerificationCode}
          name="google-site-verification"
        />
      )}
      {stage !== enableGoogleSearchEnvironment && (
        <meta content="noindex" name="robots" />
      )}
      {stage !== enableGoogleSearchEnvironment && (
        <meta content="noindex" name="googlebot" />
      )}
    </Helmet>
  );
};

GenericTags.propTypes = {
  author: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  enableGoogleSearchEnvironment: PropTypes.string,
  googleSiteVerificationCode: PropTypes.string,
  image: PropTypes.string.isRequired,
  keywords: PropTypes.string,
  language: PropTypes.string.isRequired,
  primaryColor: PropTypes.string,
  stage: PropTypes.string,
  title: PropTypes.string.isRequired,
  url: PropTypes.string.isRequired,
};

GenericTags.defaultProps = {
  enableGoogleSearchEnvironment: null,
  googleSiteVerificationCode: null,
  keywords: null,
  primaryColor: "",
  stage: "dev",
};

export default GenericTags;
