import PropTypes from "prop-types";
import React from "react";

const Copyright = ({ prepend, append }) => (
  <>
    {(prepend || append) && `${prepend} ${new Date().getFullYear()} ${append}`}
  </>
);
Copyright.propTypes = {
  append: PropTypes.string,
  prepend: PropTypes.string,
};

Copyright.defaultProps = {
  append: "",
  prepend: "© Copyright",
};

export default Copyright;
